.add-checklist-input {
  background-color: white !important;
  margin: 15px 0px 0px 0px;
}

.add-checklist-button {
  margin: 15px;
}

.form-group {
  margin: 20px
}

.form-group {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 20%;
  left: 50%;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.questions-header {
  margin-top: 30px;
  font-size: 1.5rem;
  font-weight: bold;
}
