.quality-check-details {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin: auto;
  }
  
  .section {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .section h2 {
    color: #333;
  }
  
  .question {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .answer-description {
    font-weight: bold; 
  }
  .answer-value {
    margin-top: 5px;
    color: #333; 
  }