.add-button {
    margin: 20px 0px 0px 20px;
}

.data-grid {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgb(224, 224, 224);
    overflow: hidden;

    .dx-datagrid {
        .dx-row-alt .dx-data-row,
        .dx-data-row:hover {
            background-color: rgb(220, 220, 220);
            cursor: pointer;
        }
    }
}
