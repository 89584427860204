/* You can include this in your existing CSS file or directly in the component using <style> tags */
.form-container {
    margin: 20px;
    padding: 20px;
}

.form-group {
    margin-bottom: 10px;
    margin-left: 20px;
}

.form-label {
    margin-right: 10px;
    font-weight: bold;
}

.form-input {
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-checkbox {
    margin-right: 5px;
}

.button-container {
    margin: 20px;
    text-align: left;
}

.submit-button {
    padding: 10px 20px;
    background-color: #08948c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 
    background-color: rgba(255, 255, 255, 0.8); */
    z-index: 10;
}